<template>
<v-app>
    <v-card>
        <v-card-title>
            <v-text-field v-model="search" label="Buscar aquí" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table @click:row="showFicha" :headers="headers" :items="this.productos" :search="search" group-by="proveedor.name" class="elevation-1">
            <template v-slot:group.header="{group}">
                <td colspan="100">
                    <span>Empresa:</span>
                    {{group}}
                </td>
            </template>
            <template v-slot:item.validez_campanya="{ item }">
                <v-chip :color="getColorCampanya(item)" dark>{{ item.validez_campanya}}</v-chip>
            </template>
            <template v-slot:item.descatalogado="{ item }">
                <v-chip :color="getColor(item.descatalogado)" dark>{{ (item.descatalogado)?"Sí":"No" }}</v-chip>
            </template>
        </v-data-table>
    </v-card>
</v-app>
</template>


<script>
export default {
    props: ['productos', 'showRoute', 'campanya_actual'],
    data() {
        return {
            search: '',
            headers: [{
                    text: 'Referencia Proveedor',
                    value: 'ref_proveedor',
                },
                {
                    text: 'EAN13',
                    value: 'ean13'
                },
                {
                    text: 'Descripción',
                    value: 'descripcion'
                },

                {
                    text: 'Precio Neto €',
                    value: 'precio_neto',
                    align: 'right'
                },
                {
                    text: 'Validez campaña',
                    value: 'validez_campanya',
                    align: 'center'
                },
                {
                    text: 'Descatalogado',
                    value: 'descatalogado',
                    align: 'center'
                },
                {
                    text: 'Empresa',
                    value: 'proveedor.name',
                },
            ],
        }

    },
    methods: {
        showFicha(a) {
            //window.location.href = "/productos/" + a.id;
            window.location.href = this.showRoute + "/" + a.id;
        },

        getColor(descatalogado) {
            if (descatalogado) return ''
            else return 'green'
        },
        getColorCampanya(item) {
            if (item.descatalogado) return ''
            else if (item && item.validez_campanya == this.campanya_actual) return 'green'
            else return 'red'
        },
    },

    mounted() {
        //console.log(this.productos)
    },
}
</script>
