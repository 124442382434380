<template>
  <v-app>
    <v-row justify="center">
      <v-dialog v-if="this.producto.length" v-model="dialog" max-width="90%">
        <product-card-component
          :show-empresa="this.showEmpresa"
          :show-route="this.showRoute"
          :producto="this.producto[0]"
        ></product-card-component>
      </v-dialog>
      <p v-if="!this.producto.length">No existe producto</p>
    </v-row>
  </v-app>
</template>

<script>
export default {
  props: ["producto", "showRoute", "showEmpresa"],

  data() {
    return {
      dialog: true
    };
  }
};
</script>
