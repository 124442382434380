<template>
<v-app>
    <v-card>
        <v-card-title>
            <v-text-field v-model="search" label="Buscar aquí" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table @click:row="showFicha" :headers="headers" :items="this.subcategorias" :search="search" class="elevation-1">
        </v-data-table>
    </v-card>
</v-app>
</template>


<script>
export default {
    props: ['subcategorias', 'showRoute'],
    data() {
        return {
            search: '',
            headers: [{
                text: 'Nombre',
                value: 'name',
            }, ],
        }

    },
    methods: {

        showFicha(a) {
            //window.location.href = "/categorias/" + a.id;
            window.location.href = this.showRoute + "/" + a.id;
        },
        showEmpresa(a) {
            //window.location.href = this.showEmpresaRoute + "/" + a.target.offsetParent.id;
        },


    },

    mounted() {
        //console.log(this.categorias[1].subcategorias)
    },
}
</script>
