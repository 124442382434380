<template>
  <v-app>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" label="Buscar aquí" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        @click:row="showFicha"
        :headers="headers"
        :items="this.categorias"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.subcategorias="{ item }">
          <v-chip v-for="i in item.subcategorias" :id="i.id" :key="i.id">{{ i.name }}</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>


<script>
export default {
  props: ["categorias", "showRoute"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nombre",
          value: "name"
        },
        {
          text: "Subcategorías",
          value: "subcategorias"
        }
      ]
    };
  },
  methods: {
    showFicha(a) {
      window.location.href = this.showRoute + "/" + a.id;
    }
  }
};
</script>
