<template>
  <div class="row">
    <div class="form-group col-sm-9">
      <label for="categoria_id" class="required">Categoría</label>
      <select
        required
        class="custom-select"
        id="categoria_id"
        name="categoria_id"
        v-model="categoria"
        @change="selectCategoria(categoria.value)"
      >
        <option value></option>
        <option v-for="(cat,key) in this.categorias" :key="key" v-bind:value="cat.id">{{cat.name}}</option>
      </select>
      <div v-if="this.errors.categoria_id" class="text-danger" role="alert">
        <strong v-for="(err,key) in this.errors.categoria_id" :key="key">{{err}}</strong>
      </div>
    </div>
    <div class="form-group col-sm-3">
      <label
        for="subcategoria_id"
        v-bind:class="{required : !(!this.subcategorias_list)}"
      >Subcategoría</label>
      <select
        :disabled="!this.subcategorias_list"
        :required="!(!this.subcategorias_list)"
        class="custom-select"
        id="subcategoria_id"
        name="subcategoria_id"
        v-model="subcategoria"
      >
        <option
          v-for="sub in this.subcategorias_list"
          :key="sub.id"
          v-bind:value="sub.id"
        >{{sub.name}}</option>
      </select>
      <div v-if="this.errors.subcategoria_id" class="text-danger" role="alert">
        <strong v-for="(err,key) in this.errors.subcategoria_id" :key="key">{{err}}</strong>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ["producto", "categorias", "old", "errors"],

  mounted() {
    /*console.log(this.old);
        console.log(this.errors);*/

    if (this.old.categoria_id) {
      this.categoria = this.old.categoria_id;

      if (this.old.subcategoria_id) {
        this.subcategoria = this.old.subcategoria_id;
      } else {
        this.subcategoria = "";
      }
    } else if (this.producto) {
      if (this.producto.subcategoria) {
        this.subcategoria = this.producto.subcategoria.id;
      } else {
        this.subcategoria = "";
      }

      if (this.producto.categoria) {
        this.categoria = this.producto.categoria.id;
      } else {
        this.categoria = "";
      }
    } else {
      this.categoria = this.subcategoria = "";
    }

    if (
      this.categoria &&
      this.categorias.find(x => x.id == this.categoria).subcategorias.length > 0
    ) {
      this.subcategorias_list = this.categorias.find(
        x => x.id == this.categoria
      ).subcategorias;
    } else {
      this.subcategoria = "";
      this.subcategorias_list = "";
    }
  },
  data() {
    return {
      categoria: "",
      subcategoria: "",
      subcategorias_list: ""
    };
  },

  methods: {
    selectCategoria: function(categoria) {
      if (
        this.categoria &&
        this.categorias.find(x => x.id === this.categoria).subcategorias
          .length > 0
      ) {
        this.subcategoria = this.categorias.find(
          x => x.id === this.categoria
        ).subcategorias[0].id;
        this.subcategorias_list = this.categorias.find(
          x => x.id === this.categoria
        ).subcategorias;
      } else {
        this.subcategoria = "";
        this.subcategorias_list = "";
      }
      //console.log(this.subcategoria)
    }
  }
};
</script>
