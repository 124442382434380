<template>
<v-app>
    <v-card>
        <v-card-title>
            <v-text-field v-model="search" label="Buscar aquí" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table @click:row="showFicha" :headers="headers" :items="this.users" :search="search" class="elevation-1">
            <template v-slot:item.empresas="{ item }">
                <v-chip v-for="i in item.empresas" :id="i.id" :key="i.id">{{ i.name }}</v-chip>
            </template>
        </v-data-table>
    </v-card>
</v-app>
</template>


<script>
export default {
    props: ['users', 'showRoute', 'showEmpresaRoute'],
    data() {
        return {
            search: '',
            headers: [{
                    text: 'Nombre',
                    value: 'name',
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Empresas',
                    value: 'empresas'
                },

            ],
        }

    },
    methods: {

        showFicha(a) {
            //window.location.href = "/users/" + a.id;
            window.location.href = this.showRoute + "/" + a.id;
        },
        showEmpresa(a) {
            //window.location.href = this.showEmpresaRoute + "/" + a.target.offsetParent.id;
        },


    },

    mounted() {
        //console.log(this.users[1].empresas)
    },
}
</script>
