<template>
  <v-app class="px-0">
    <v-col class="px-0 py-0" cols="12">
      <v-card v-if="!Object.entries(this.producto).length" class="primary-bg" dark>
        <p>No se ha podido cargar el producto</p>
      </v-card>
      <a
        v-if="Object.entries(this.producto).length"
        :href="this.show"
        style="color:white; text-decoration: none;"
      >
        <v-card class="primary-bg" dark>
          <div class="row d-flex">
            <div class="px-3 py-0">
              <v-card-title class="headline" v-text="this.producto.ref_proveedor"></v-card-title>
              <v-card-subtitle v-text="this.producto.cod_interno"></v-card-subtitle>
            </div>
            <div class="px-3 py-0">
              <barcode
                class="m-3"
                format="EAN13"
                height="30"
                fontSize="15"
                :value="this.producto.ean13"
              >EAN NO DISPONIBLE</barcode>
            </div>
            <div class="px-3 ml-md-auto">
              <a :href="this.imgDownload" id="imgDownloadLink" download>
                <img
                  :src="this.imgUrl"
                  alt="imagen producto"
                  style="max-width:125px; max-height:125px"
                  class="m-3"
                />
              </a>
            </div>
          </div>
          <v-card-title class="headline">{{this.producto.descripcion}}</v-card-title>

          <v-card-subtitle
            v-if="this.producto.precio_neto"
          >{{this.producto.precio_neto}} €/{{this.producto.tipo_precio}} (Campaña {{this.producto.validez_campanya}})</v-card-subtitle>
          <p class="px-3 py-0">Peso/Volumen: {{this.producto.peso_volumen}}</p>
          <p class="px-3 py-0">Almacenar: {{this.producto.temp_almacen}}</p>
          <p class="px-3 py-0">Categoría: {{this.producto.categoria.name}}</p>
          <p class="px-3 py-0">Subcategoría: {{this.subcategoria}}</p>
          <p class="px-3 py-0">
            <a
              style="color:white; text-decoration: none;"
              :href="this.empresaShow"
              id="proveedorLink"
            >Proveedor: {{this.producto.proveedor.name}}</a>
          </p>
          <div class="row d-flex text-center">
            <div class="col-md-3 col-lg-1 col-6">
              <p>Musulmanes:</p>
              <v-tristate-icon-component :value-to-show="this.producto.musulmanes"></v-tristate-icon-component>
            </div>
            <div class="col-md-3 col-lg-1 col-6">
              <p>Celíacos:</p>
              <v-tristate-icon-component :value-to-show="this.producto.celiacos"></v-tristate-icon-component>
            </div>
            <div class="col-md-3 col-lg-1 col-6">
              <p>Vegano:</p>
              <v-tristate-icon-component :value-to-show="this.producto.vegano"></v-tristate-icon-component>
            </div>
            <div class="col-md-3 col-lg-1 col-6">
              <p>Ecológico:</p>
              <v-tristate-icon-component :value-to-show="this.producto.ecologico"></v-tristate-icon-component>
            </div>
          </div>

          <p class="px-3">Comentarios: {{this.producto.comentario}}</p>

          <p class="px-3 mb-0">Última modificación: {{this.producto.updated_at}}</p>

          <div class="d-flex text-center">
            <v-card-actions class="mt-4">
              <v-btn link :href="this.edit" style="color:white; text-decoration: none;">Editar</v-btn>
            </v-card-actions>

            <v-card-actions v-if="!this.producto.descatalogado" class="mt-4 ml-auto">
              <form class :action="this.descatalogar" method="POST">
                <input type="hidden" name="_token" :value="getCSRF()" />
                <input type="hidden" name="descatalogado" value="1" />
                <v-btn type="submit">Descatalogar</v-btn>
              </form>
            </v-card-actions>

            <v-card-actions v-if="this.producto.descatalogado" class="mt-4 ml-auto">
              <form class :action="this.descatalogar" method="POST">
                <input type="hidden" name="_token" :value="getCSRF()" />
                <input type="hidden" name="descatalogado" value="0" />
                <v-btn type="submit">Reactivar</v-btn>
              </form>
            </v-card-actions>
          </div>
          <span
            v-if="this.producto.descatalogado"
            class="corner-label-container corner-label-container--tight"
          >
            <span class="corner-label corner-label--dark corner-label--top-right">Descatalogado</span>
          </span>
        </v-card>
      </a>
    </v-col>
  </v-app>
</template>


<script>
export default {
  props: ["producto", "showRoute", "showEmpresa"],

  created() {
    if (Object.entries(this.producto).length) {
      this.imgUrl = this.showRoute + "/" + this.producto.id + "/images";
      this.imgDownload =
        this.showRoute + "/" + this.producto.id + "/images/download";
      this.edit = this.showRoute + "/" + this.producto.id + "/edit";
      this.show = this.showRoute + "/" + this.producto.id;
      this.empresaShow = this.showEmpresa + "/" + this.producto.proveedor.id;
      this.descatalogar =
        this.showRoute + "/" + this.producto.id + "/descatalogar";
    }
  },
  data() {
    return {
      subcategoria: "",
      imgUrl: "",
      imgDownload: "",
      edit: "",
      show: "",
      empresaShow: "",
      descatalogar: ""
    };
  },
  mounted() {
    if (Object.entries(this.producto).length) {
      if (this.producto.subcategoria) {
        this.subcategoria = this.producto.subcategoria.name;
      } else {
        this.subcategoria = "";
      }
    }
  },

  methods: {
    getCSRF: function() {
      return document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");
    }
  }
};
</script>


<style lang="scss" scoped>
$primary-bg: #385f73;
@import "~@/_variables.scss";
@import "~@/_customvariables.scss";

::v-deep .rojo,
.rojo {
  color: red !important;
}

.verde,
::v-deep .verde {
  color: green !important;
}

::v-deep .v-application .primary-bg,
.v-application .primary-bg {
  background-color: $primary-bg !important;
}
</style>
