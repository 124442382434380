/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

//IE dependencies
import 'string.prototype.startswith';
import "babel-polyfill";
import 'whatwg-fetch';



require('./bootstrap');
require('bootstrap-fileinput/js/fileinput.min.js');
require('bootstrap-fileinput/js/locales/es.js');
require('bootstrap-fileinput/themes/fas/theme.min.js');


//require('select2/dist/js/select2.min.js');
//require('select2/dist/js/i18n/es.js');

/*import 'select2'; // globally assign select2 fn to $ element

$(() => {
    $('.select2-enable').select2({
        language: "es"
    });
});*/

window.Vue = require('vue');



import Vuetify from 'vuetify';
Vue.use(Vuetify);


import VueBarcode from 'vue-barcode'
Vue.component('barcode', VueBarcode)


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('v-tristate-icon-component', require('./components/VTristateIconComponent.vue').default);
Vue.component('product-preview', require('./components/ProductPreview.vue').default);
Vue.component('product-card-component', require('./components/ProductCardComponent.vue').default);
Vue.component('product-category-subcategory-component', require('./components/ProductCategorySubcategoryComponent.vue').default);
Vue.component('advanced-search-product-category-subcategory-component', require('./components/AdvancedSearchProductCategorySubcategoryComponent.vue').default);
Vue.component('seach-sort-productos-table-component', require('./components/SearchSortProductosTableComponent.vue').default);
Vue.component('advanced-seach-sort-productos-table-component', require('./components/AdvancedSearchSortProductosTableComponent.vue').default);
Vue.component('seach-sort-empresas-table-component', require('./components/SearchSortEmpresasTableComponent.vue').default);
Vue.component('seach-sort-notifications-table-component', require('./components/SearchSortNotificationsTableComponent.vue').default);
Vue.component('seach-sort-users-table-component', require('./components/SearchSortUsersTableComponent.vue').default);
Vue.component('seach-sort-categorias-table-component', require('./components/SearchSortCategoriasTableComponent.vue').default);
Vue.component('seach-sort-subcategorias-table-component', require('./components/SearchSortSubcategoriasTableComponent.vue').default);
Vue.component('modal-product-search-view', require('./components/ModalProductSearchView.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    vuetify: new Vuetify({}),
    /*  barcode: new VueBarcode({})*/
    mounted() { //para que funcione el autofocus
        let input = document.querySelector('[autofocus]');
        if (input) {
            input.focus()
        }
        if (input && input.hasOwnProperty("id") && input.id == 'ean13') {
            input.select()
        }
    }
});
