<template>
  <v-app>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" label="Buscar aquí" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table
        @click:row="showFicha"
        :headers="headers"
        :items="this.productos"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:item.validez_campanya="{ item }">
          <v-chip :color="getColorCampanya(item)" dark>{{ item.validez_campanya}}</v-chip>
        </template>
        <template v-slot:item.musulmanes="{ item }">
          <v-tristate-icon-component :value-to-show="item.musulmanes"></v-tristate-icon-component>
        </template>
        <template v-slot:item.celiacos="{ item }">
          <v-tristate-icon-component :value-to-show="item.celiacos"></v-tristate-icon-component>
        </template>
        <template v-slot:item.vegano="{ item }">
          <v-tristate-icon-component :value-to-show="item.vegano"></v-tristate-icon-component>
        </template>
        <template v-slot:item.ecologico="{ item }">
          <v-tristate-icon-component :value-to-show="item.ecologico"></v-tristate-icon-component>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>


<script>
export default {
  props: ["productos", "showRoute", "campanya_actual"],
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Cod. Interno",
          value: "cod_interno"
        },
        {
          text: "EAN13",
          value: "ean13"
        },
        {
          text: "Descripción",
          value: "descripcion"
        },

        {
          text: "Precio Neto €",
          value: "precio_neto",
          align: "right"
        },
        {
          text: "Validez campaña",
          value: "validez_campanya",
          align: "center"
        },
        {
          text: "Temperatura",
          value: "temp_almacen"
        },
        {
          text: "Musulmanes",
          value: "musulmanes"
        },
        {
          text: "Celíacos",
          value: "celiacos"
        },
        {
          text: "Vegano",
          value: "vegano"
        },
        {
          text: "Ecológico",
          value: "ecologico"
        },
        {
          text: "Empresa",
          value: "proveedor.name"
        }
      ]
    };
  },
  methods: {
    showFicha(product) {
      //window.location.href = "/productos/" + product.id;
      window.location.href = this.showRoute + "/" + product.id;
    },
    getColorCampanya(item) {
      if (item.descatalogado) return "";
      else if (item && item.validez_campanya == this.campanya_actual)
        return "green";
      else return "red";
    }
  },

  mounted() {
    //console.log(this.productos)
  }
};
</script>
<style scoped>
::v-deep .rojo {
  color: red !important;
}

::v-deep .verde {
  color: green !important;
}
</style>
