<template>
<v-app>
    <v-card>
        <v-card-title>
            <v-text-field v-model="search" label="Buscar aquí" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table @click:row="showFicha" :headers="headers" :items="this.empresas" :search="search" group-by="representante.name" class="elevation-1">
            <template v-slot:group.header="{group}">
                <td colspan="100">
                    <span>Representante:</span>
                    {{group}}
                </td>
            </template>
        </v-data-table>
    </v-card>
</v-app>
</template>


<script>
export default {
    props: ['empresas', 'showRoute'],
    data() {
        return {
            search: '',
            headers: [{
                    text: 'NIF Empresa',
                    value: 'nif',
                },
                {
                    text: 'Nombre',
                    value: 'name'
                },
                {
                    text: 'Representante',
                    value: 'representante.name',
                    filterable: false,
                },

            ],
        }

    },
    methods: {
        showFicha(a) {
            //window.location.href = "/empresas/" + a.id;
            window.location.href = this.showRoute + "/" + a.id;
        },

    },

    mounted() {
        //console.log(this.empresas)
    },
}
</script>
