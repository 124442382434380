<template>
  <a class="flex self-stretch hover:no-underline" :href="this.show">
    <div class="bg-teal-900 p-3 m-2 flex space-x-3 rounded-lg text-white">
      <div class="w-1/3 md:w-1/4 flex flex-col space-y-0">
        <p class="mb-1 md:ml-0 ml-1 font-bold">{{truncate(producto.cod_interno,50)}}</p>
        <div class="self-center">
          <img
            class="rounded"
            :src="this.imgUrl"
            alt="imagen"
            style="max-width:80px; max-height:80px"
          />
        </div>
        <div class="flex text-center pt-2">
          <p class="w-1/4 mb-0" v-bind:class="colorear(producto.musulmanes)">M</p>
          <p class="w-1/4 mb-0" v-bind:class="colorear(producto.celiacos)">C</p>
          <p class="w-1/4 mb-0" v-bind:class="colorear(producto.vegano)">V</p>
          <p class="w-1/4 mb-0" v-bind:class="colorear(producto.ecologico)">E</p>
        </div>
      </div>
      <div class="w-2/3 md:w-3/4">
        <p class="pt-4 mb-2">{{truncate(producto.descripcion,90)}}</p>
        <p class="mb-2">{{producto.precio_neto}} €/{{producto.tipo_precio}}</p>
        <p class="mb-2">{{this.producto.proveedor.name}}</p>
      </div>
    </div>
  </a>
</template>


<script>
export default {
  props: ["producto", "showRoute", "showEmpresa"],

  created() {
    this.imgUrl = this.showRoute + "/" + this.producto.id + "/images";
    this.edit = this.showRoute + "/" + this.producto.id + "/edit";
    this.show = this.showRoute + "/" + this.producto.id;
    this.empresaShow = this.showEmpresa + "/" + this.producto.proveedor.id;
    this.descatalogar =
      this.showRoute + "/" + this.producto.id + "/descatalogar";
  },
  data() {
    return {
      csrf: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      subcategoria: ""
    };
  },
  mounted() {
    //console.log(this.subcategoria);
    if (this.producto) {
      if (this.producto.subcategoria) {
        this.subcategoria = this.producto.subcategoria.name;
      } else {
        this.subcategoria = "";
      }
    }
    //console.log(this.producto.celiacos);
  },
  methods: {
    truncate: function(value, length) {
      if (value.length < length) {
        return value;
      }

      length = length - 3;
      return value.substring(0, length) + "...";
    },

    colorear: function(campo) {
      var colorClass = "text-black";
      if (campo === null) {
        colorClass = "";
      } else if (campo == "1") {
        colorClass = "text-green-500";
      } else if (campo == "0") {
        colorClass = "text-red-600";
      }
      return colorClass;
    }
  }
};
</script>
