<template>
<v-app>
    <v-card>
        <v-card-title>
            <v-text-field v-model="search" label="Buscar aquí" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table @click:row="showFicha" :headers="headers" :items="this.notifications" :search="search" class="elevation-1">
            <template v-slot:item.campo="{ item }">
                <v-chip :color="getColor(item.campo)" dark>{{ item.campo}}</v-chip>
            </template>
        </v-data-table>
    </v-card>
</v-app>
</template>


<script>
export default {
    props: ['notifications', 'showRoute'],
    data() {
        return {
            search: '',
            headers: [{
                    text: 'Campo',
                    value: 'campo',
                },
                {
                    text: 'Fecha',
                    value: 'fecha'
                },
                {
                    text: 'Producto',
                    value: 'producto'
                },

                {
                    text: 'Usuario',
                    value: 'usuario'
                },

            ],
        }

    },
    methods: {
        showFicha(a) {
            //window.location.href = "/notifications/" + a.id;
            window.location.href = this.showRoute + "/" + a.id;
        },

        getColor(campo) {
            if (campo == 'PRECIO') return 'red'
            if (campo == 'DESCAT') return 'red darken-4'
            if (campo == 'REF') return 'orange'


        },
    },

    mounted() {
        //console.log(this.notifications)
    },
}
</script>
