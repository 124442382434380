var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"label":"Buscar aquí","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":this.productos,"search":_vm.search},on:{"click:row":_vm.showFicha},scopedSlots:_vm._u([{key:"item.validez_campanya",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorCampanya(item),"dark":""}},[_vm._v(_vm._s(item.validez_campanya))])]}},{key:"item.musulmanes",fn:function(ref){
var item = ref.item;
return [_c('v-tristate-icon-component',{attrs:{"value-to-show":item.musulmanes}})]}},{key:"item.celiacos",fn:function(ref){
var item = ref.item;
return [_c('v-tristate-icon-component',{attrs:{"value-to-show":item.celiacos}})]}},{key:"item.vegano",fn:function(ref){
var item = ref.item;
return [_c('v-tristate-icon-component',{attrs:{"value-to-show":item.vegano}})]}},{key:"item.ecologico",fn:function(ref){
var item = ref.item;
return [_c('v-tristate-icon-component',{attrs:{"value-to-show":item.ecologico}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }