<template>
  <span class="transparent">
    <v-icon v-if="this.valueToShow === 1" class="verde px-3 pt-0">far fa-check-circle</v-icon>
    <v-icon v-else-if="this.valueToShow === 0" class="rojo px-3 pt-0">far fa-times-circle</v-icon>
    <v-icon v-else class="px-3 pt-0">far fa-question-circle</v-icon>
  </span>
</template>


<script>
export default {
  props: ["valueToShow"]
};
</script>


